'use client'

import { useSelector } from 'react-redux'
import { Cell, Image, Rating, Spacer, Text } from '@vinted/web-ui'

import UserImage from 'components/UserImage'
import UserTitle from 'components/UserTitle'
import useTranslate from 'hooks/useTranslate'

import { MEMBER_PROFILE_URL } from 'constants/routes'
import { calculateRating } from 'data/utils/user'
import * as selectors from 'state/auth/selectors'
import { ReferrerModel } from 'types/models'
import useAuthModal from 'libs/common/auth-modal/useAuthModal'

type Props = {
  referrer: ReferrerModel | undefined
}

const SelectTypeHeader = ({ referrer }: Props) => {
  const isLoginView = useSelector(selectors.getIsSelectTypeLogin)
  const { isBusinessAuth } = useAuthModal()
  const authTranslate = useTranslate(`auth.select_type.${isLoginView ? 'login' : 'register'}`)
  const businessTranslate = useTranslate('business.account')
  const userFeedbackTranslate = useTranslate('user.feedback')

  const renderSubtitle = () => {
    if (!isBusinessAuth) return null

    return (
      <>
        <Spacer size={Spacer.Size.Large} />
        <Text width={Text.Width.Parent} alignment={Text.Alignment.Center}>
          {businessTranslate('subtitle')}
        </Text>
      </>
    )
  }

  const renderTitle = () => {
    return (
      <Text
        id="auth_modal_title"
        text={isBusinessAuth ? businessTranslate('title') : authTranslate('title')}
        type={Text.Type.Heading}
        alignment={Text.Alignment.Center}
        width={Text.Width.Parent}
      />
    )
  }

  const renderReferrer = ({
    referrerId,
    login,
    photoUrl,
    feedbackReputation,
    feedbackCount,
    isBusiness,
    businessAccountName,
  }: ReferrerModel) => (
    <>
      <div className="u-flexbox u-justify-content-center">
        <div>
          <Cell
            clickable
            styling={Cell.Styling.Tight}
            prefix={
              <UserImage src={photoUrl} styling={Image.Styling.Circle} size={Image.Size.Large} />
            }
            title={
              <UserTitle
                businessAccountName={businessAccountName || undefined}
                login={login}
                isBusiness={isBusiness}
                isBody
                isBottomLoginHidden
                isBadgeHidden
              />
            }
            url={MEMBER_PROFILE_URL(referrerId)}
          >
            <Rating
              value={calculateRating(feedbackReputation)}
              text={userFeedbackTranslate(
                'reviews',
                { count: feedbackCount },
                { count: feedbackCount },
              )}
              size={Rating.Size.Regular}
              emptyStateText={userFeedbackTranslate('no_reviews')}
            />
          </Cell>
        </div>
      </div>
      <Spacer size={Spacer.Size.Large} />
      <Text
        text={authTranslate('referrer_title', { username: login })}
        type={Text.Type.Heading}
        alignment={Text.Alignment.Center}
        width={Text.Width.Parent}
      />
    </>
  )

  if (referrer && !isLoginView) {
    return renderReferrer(referrer)
  }

  return (
    <>
      {renderTitle()}
      {renderSubtitle()}
    </>
  )
}

export default SelectTypeHeader

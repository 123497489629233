import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { getAuthView } from 'state/auth/selectors'
import { Screen } from 'constants/tracking/screens'
import { AuthExternalRegisterView, AuthView } from 'state/auth/constants'
import useTracking from 'hooks/useTracking'
import { clickEvent, userInputEvent, viewScreenEvent } from 'libs/common/event-tracker/events'
import useLocation from 'hooks/useLocation'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import useAuthModal from 'libs/common/auth-modal/useAuthModal'

type ViewToScreenMap = { [key in AuthView]: Screen } & {
  [key in AuthExternalRegisterView]: Screen
}

const viewToScreenMap: ViewToScreenMap = {
  [AuthView.SelectTypeRegister]: Screen.AuthSelectTypeRegister,
  [AuthView.SelectTypeLogin]: Screen.AuthSelectTypeLogin,
  [AuthView.EmailLogin]: Screen.AuthEmailLogin,
  [AuthView.EmailRegister]: Screen.AuthEmailRegister,
  [AuthView.ResetPassword]: Screen.AuthResetPassword,
  [AuthView.TwoFactorLogin]: Screen.AuthTwoFactorLogin,
  [AuthExternalRegisterView.FacebookRegister]: Screen.AuthFacebookRegister,
  [AuthExternalRegisterView.GoogleRegister]: Screen.AuthGoogleRegister,
  [AuthExternalRegisterView.AppleRegister]: Screen.AuthAppleRegister,
  [AuthExternalRegisterView.PasswordVerification]: Screen.AuthPasswordVerification,
}

const useAuthTracking = () => {
  const view = useSelector(getAuthView)
  const { isAuthModalOpen } = useAuthModal()
  const { track } = useTracking()
  const { relativeUrl } = useLocation()

  const screen = viewToScreenMap[view]
    ? (`${viewToScreenMap[view]}${isAuthModalOpen ? '_modal' : ''}` as Screen)
    : undefined

  const trackViewScreen = useCallback(() => {
    if (!screen) return

    track(viewScreenEvent({ screen }))
  }, [track, screen])

  const trackClickEvent = useCallback(
    ({ target, targetDetails }: { target: ClickableElement; targetDetails?: string }) => {
      track(clickEvent({ path: relativeUrl, screen, target, targetDetails }))
    },
    [relativeUrl, screen, track],
  )

  const trackInputEvent = useCallback(
    ({ target, state }: { target: string; state: 'focus' | 'unfocus' }) => {
      track(userInputEvent({ screen, target, state }))
    },
    [screen, track],
  )

  return { trackViewScreen, trackClickEvent, trackInputEvent }
}

export default useAuthTracking
